import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../components/Layout';
import PageSection from '../components/Elements/PageSection';
import CoverImage from '../components/Pages/NotFound/CoverImage';
import withTranslations from '../i18n/withTranslations';

const NotFound = () => {
  const { t } = useTranslation('404');

  return (
    <Layout className="not-found home">
      <Seo title={t('seoTitle')} />
      <div className="not-found-image" />
      <CoverImage>
        <PageSection center>
          <h1>{t('title')}</h1>
          <h2>{t('subTitle')}</h2>
        </PageSection>
      </CoverImage>
    </Layout>
  );
};

export default withTranslations(NotFound);
